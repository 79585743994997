<template>
  <AdminPage contentComponent="AppAdminSpaceDownloadCsv" />
</template>

<script>
import AdminPage from '@/templates/admin/AppAdminPage'

export default {
  name: 'TheSpaceDownloadCsv',
  components: {
    AdminPage,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>
